@import url('https://fonts.googleapis.com/css2?family=Black+Ops+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');

h1, h2 {
    font-family: 'Black Ops One', sans-serif !important;
    text-transform: uppercase;
}

.h1,
.h2 {
    font-family: 'Black Ops One', sans-serif !important;
    text-transform: uppercase;
}

p,
div,
a,
input,
body {
    font-family: "Inter", sans-serif !important;
    font-size: 1.2rem;
}

h3,
h4,
h5,
h6, .h3,
.h4,
.h5,
.h6 {
    font-family: "Inter", sans-serif !important;
    text-transform: uppercase;
    font-weight: 700;
}

.display-2, .display-3, .display-4, .display-5 {
    font-weight: 700;
}



