:root {
    --gray: #2c2c2c;
    --dark: #1e1e1e;
    --light: #f8f9fa;
    --accent: #ff0000;
}

.card {
    background-color: var(--gray);
    border: 1px solid rgba(248, 249, 250, 0.5);
}

input[type="radio"], input[type="checkbox"] {
    accent-color: var(--accent);
}

input.InputElement {
    color: white !important;
}

#field-wrapper {
    border: 1px solid rgba(248, 249, 250, 0.5);
}

#field-wrapper, #field-wrapper input {   
    color: var(--light);
    background-color: var(--gray);
}

/** Border **/
.border-primary {
    border-width: 2px !important;
    border-color: var(--accent) !important;
}

/** Background **/

.bg-black {
    background-color: #000;
}

.bg-gray {
    background-color: var(--gray);
}

.bg-dark {
    background: url("../assets/images/fondo-oscuro.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-camo {
    background: url("https://musclefactory.s3.us-west-1.amazonaws.com/bg-camo.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-blur {
    background: url("https://musclefactory.s3.us-west-1.amazonaws.com/foto-hero-5-min.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-primary {
    background-color: var(--accent) !important;
}

.bg-accent {
    background-color: var(--accent);
}

/** Text **/

.text-dark {
    color: var(--dark)
}

.text-accent {
    color: var(--accent)
}

.text-primary {
    color: var(--accent) !important;
}

.btn-primary {
    background-color: var(--pink);
    color: var(--gray);
}

/** Buttons */

.btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check)+.btn:active {
    background-color: #000;
    border: 1px solid var(--light)
}

.btn-primary:disabled {
    background-color: var(--accent);
    opacity: 0.7;
}

.btn-primary {
    background-color: var(--accent);
    color: white;
}

.btn-primary:focus {
    background-color: #000;
    box-shadow: none;
    box-shadow: none;
}

.btn-primary:hover {
    color: #fff;
    background-color: #000;
    border: 1px solid #fff;
}

.btn-primary:active {
    background-color: #000;
    box-shadow: none;
}

.btn-primary:active {
    background-color: #000;
    box-shadow: none;
}

.btn-secondary {
    background-color: var(--gray);
    border-color: var(--gray);
}

.btn-outline-primary {
    border-color: var(--accent);
    color: var(--accent);
}

.btn-outline-primary:disabled {
    border-color: var(--accent);
    color: var(--accent);
}

.btn-outline-primary:hover {
    background-color: var(--accent);
    border: var(--accent);
}

/** Forms **/


.bg-gray .form-control, .bg-gray .form-control:focus {
    background-color: var(--dark);
}


.form-control, .form-control:focus, .form-control:disabled {
    background-color: var(--gray) !important;
    color: #fff;
}

/** General **/

.modal-content {
    color: white;
    background-color: var(--dark);
}

.menu-category:hover {
    background-color: var(--accent);
}

.menu-category:active {
    box-shadow: none;
    background-color: var(--accent);
}

.menu-category:focus {
    box-shadow: none;
    background-color: var(--accent);
}

.nav-item:hover {
    background-color: var(--accent);
    color: #000;
}

.dropdown-item.active,
.dropdown-item:active {
    background-color: var(--accent);
    color: #fff;
}

.user {
    background-color: var(--accent);
}

.nav-item .mobile:focus {
    background-color: var(--accent);
}

.image-input-container {
    border: 2px solid var(--accent);
}

.user-menu {
    background-color: var(--gray);
}


.hover-light:hover {
    color: var(--dark) !important;
    background-color: var(--light);
}