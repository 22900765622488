.feature-carousel-wrapper {
    width: 100%;
    max-width: 100%;
    overflow-y: hidden;
    overflow-x: scroll;
}

.feature-carousel-item {
    width: 300px;
    display: inline-block;
    margin-right: 16px;
}

.feature-carousel {
    margin: auto;
    display: block;
}

.banner {
    width: 100%;
    border-radius: 15px;
    height: calc(40vh + 100px)
}

.banner-wrapper {
    display: inline-block;
    max-width: 90vw;
    padding: 24px;
}

li:not(.nav-item) {
    margin-bottom: 16px;
}


.hero-image {
    position: absolute;
    max-width: 60vw;
    bottom: -30vh;
    right: -12vw;
}

.hero-row {
     align-items: center;
}

#hero {
    min-height: 570px;
    height: calc(60vh + 12rem);
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("https://musclefactory.s3.us-west-1.amazonaws.com/bg-desktop.jpg");
}

#hero img {
    max-width: 500px;
}

#navbarNav {
    margin-left: 16px;
    max-width: calc(50vw - 16px);
}



@media(min-width: 2000px) {
    #hero {
        background-image: url("https://musclefactory.s3.us-west-1.amazonaws.com/bg-wide.jpg");
    }
}

@media (max-width: 1400px) {
    .display-1 {
        font-size: 4rem !important;
    }
}

@media(max-width: 1600px) {
    #hero {
        background-image: url("https://musclefactory.s3.us-west-1.amazonaws.com/bg-tablet.jpg");
    }
}

@media (max-width: 1200px) {
    #hero {
        background-position: -30vw;
    }
}


@media(max-width: 1100px) {
    #hero {
        background-position: center;
        background-image: url("https://musclefactory.s3.us-west-1.amazonaws.com/bg-tablet-vertical.jpg");
    }
}

@media (max-width: 767px) {
    .display-1 {
        font-size: 3rem !important;
    }
    .feature-card p {
        font-size: 14px !important;
    }
    .hero-row {
        align-items: end;
    }
    #app-landing #hero {
        padding-top: 0px !important;
    }
    #hero {
        height: 70vh !important;
        padding-top: 100px !important;
        background-position: center;
        background-image: url("https://musclefactory.s3.us-west-1.amazonaws.com/bg-mobile.jpg");
    }
    
}

@media(max-height: 700px) {
    #app-landing #hero {
        min-height: auto;
        background-position-y: -10vh;
    }
}