@import url(https://fonts.googleapis.com/css2?family=Black+Ops+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap);
.feature-carousel-wrapper {
    width: 100%;
    max-width: 100%;
    overflow-y: hidden;
    overflow-x: scroll;
}

.feature-carousel-item {
    width: 300px;
    display: inline-block;
    margin-right: 16px;
}

.feature-carousel {
    margin: auto;
    display: block;
}

.banner {
    width: 100%;
    border-radius: 15px;
    height: calc(40vh + 100px)
}

.banner-wrapper {
    display: inline-block;
    max-width: 90vw;
    padding: 24px;
}

li:not(.nav-item) {
    margin-bottom: 16px;
}


.hero-image {
    position: absolute;
    max-width: 60vw;
    bottom: -30vh;
    right: -12vw;
}

.hero-row {
     align-items: center;
}

#hero {
    min-height: 570px;
    height: calc(60vh + 12rem);
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("https://musclefactory.s3.us-west-1.amazonaws.com/bg-desktop.jpg");
}

#hero img {
    max-width: 500px;
}

#navbarNav {
    margin-left: 16px;
    max-width: calc(50vw - 16px);
}



@media(min-width: 2000px) {
    #hero {
        background-image: url("https://musclefactory.s3.us-west-1.amazonaws.com/bg-wide.jpg");
    }
}

@media (max-width: 1400px) {
    .display-1 {
        font-size: 4rem !important;
    }
}

@media(max-width: 1600px) {
    #hero {
        background-image: url("https://musclefactory.s3.us-west-1.amazonaws.com/bg-tablet.jpg");
    }
}

@media (max-width: 1200px) {
    #hero {
        background-position: -30vw;
    }
}


@media(max-width: 1100px) {
    #hero {
        background-position: center;
        background-image: url("https://musclefactory.s3.us-west-1.amazonaws.com/bg-tablet-vertical.jpg");
    }
}

@media (max-width: 767px) {
    .display-1 {
        font-size: 3rem !important;
    }
    .feature-card p {
        font-size: 14px !important;
    }
    .hero-row {
        align-items: end;
    }
    #app-landing #hero {
        padding-top: 0px !important;
    }
    #hero {
        height: 70vh !important;
        padding-top: 100px !important;
        background-position: center;
        background-image: url("https://musclefactory.s3.us-west-1.amazonaws.com/bg-mobile.jpg");
    }
    
}

@media(max-height: 700px) {
    #app-landing #hero {
        min-height: auto;
        background-position-y: -10vh;
    }
}
:root {
    --gray: #2c2c2c;
    --dark: #1e1e1e;
    --light: #f8f9fa;
    --accent: #ff0000;
}

.card {
    background-color: #2c2c2c;
    background-color: var(--gray);
    border: 1px solid rgba(248, 249, 250, 0.5);
}

input[type="radio"], input[type="checkbox"] {
    accent-color: #ff0000;
    accent-color: var(--accent);
}

input.InputElement {
    color: white !important;
}

#field-wrapper {
    border: 1px solid rgba(248, 249, 250, 0.5);
}

#field-wrapper, #field-wrapper input {   
    color: #f8f9fa;   
    color: var(--light);
    background-color: #2c2c2c;
    background-color: var(--gray);
}

/** Border **/
.border-primary {
    border-width: 2px !important;
    border-color: #ff0000 !important;
    border-color: var(--accent) !important;
}

/** Background **/

.bg-black {
    background-color: #000;
}

.bg-gray {
    background-color: #2c2c2c;
    background-color: var(--gray);
}

.bg-dark {
    background: url(/static/media/fondo-oscuro.b530a34a.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-camo {
    background: url("https://musclefactory.s3.us-west-1.amazonaws.com/bg-camo.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-blur {
    background: url("https://musclefactory.s3.us-west-1.amazonaws.com/foto-hero-5-min.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-primary {
    background-color: #ff0000 !important;
    background-color: var(--accent) !important;
}

.bg-accent {
    background-color: #ff0000;
    background-color: var(--accent);
}

/** Text **/

.text-dark {
    color: #1e1e1e;
    color: var(--dark)
}

.text-accent {
    color: #ff0000;
    color: var(--accent)
}

.text-primary {
    color: #ff0000 !important;
    color: var(--accent) !important;
}

.btn-primary {
    background-color: var(--pink);
    color: #2c2c2c;
    color: var(--gray);
}

/** Buttons */

.btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check)+.btn:active {
    background-color: #000;
    border: 1px solid #f8f9fa;
    border: 1px solid var(--light)
}

.btn-primary:disabled {
    background-color: #ff0000;
    background-color: var(--accent);
    opacity: 0.7;
}

.btn-primary {
    background-color: #ff0000;
    background-color: var(--accent);
    color: white;
}

.btn-primary:focus {
    background-color: #000;
    box-shadow: none;
    box-shadow: none;
}

.btn-primary:hover {
    color: #fff;
    background-color: #000;
    border: 1px solid #fff;
}

.btn-primary:active {
    background-color: #000;
    box-shadow: none;
}

.btn-primary:active {
    background-color: #000;
    box-shadow: none;
}

.btn-secondary {
    background-color: #2c2c2c;
    background-color: var(--gray);
    border-color: #2c2c2c;
    border-color: var(--gray);
}

.btn-outline-primary {
    border-color: #ff0000;
    border-color: var(--accent);
    color: #ff0000;
    color: var(--accent);
}

.btn-outline-primary:disabled {
    border-color: #ff0000;
    border-color: var(--accent);
    color: #ff0000;
    color: var(--accent);
}

.btn-outline-primary:hover {
    background-color: #ff0000;
    background-color: var(--accent);
    border: #ff0000;
    border: var(--accent);
}

/** Forms **/


.bg-gray .form-control, .bg-gray .form-control:focus {
    background-color: #1e1e1e;
    background-color: var(--dark);
}


.form-control, .form-control:focus, .form-control:disabled {
    background-color: #2c2c2c !important;
    background-color: var(--gray) !important;
    color: #fff;
}

/** General **/

.modal-content {
    color: white;
    background-color: #1e1e1e;
    background-color: var(--dark);
}

.menu-category:hover {
    background-color: #ff0000;
    background-color: var(--accent);
}

.menu-category:active {
    box-shadow: none;
    background-color: #ff0000;
    background-color: var(--accent);
}

.menu-category:focus {
    box-shadow: none;
    background-color: #ff0000;
    background-color: var(--accent);
}

.nav-item:hover {
    background-color: #ff0000;
    background-color: var(--accent);
    color: #000;
}

.dropdown-item.active,
.dropdown-item:active {
    background-color: #ff0000;
    background-color: var(--accent);
    color: #fff;
}

.user {
    background-color: #ff0000;
    background-color: var(--accent);
}

.nav-item .mobile:focus {
    background-color: #ff0000;
    background-color: var(--accent);
}

.image-input-container {
    border: 2px solid #ff0000;
    border: 2px solid var(--accent);
}

.user-menu {
    background-color: #2c2c2c;
    background-color: var(--gray);
}


.hover-light:hover {
    color: #1e1e1e !important;
    color: var(--dark) !important;
    background-color: #f8f9fa;
    background-color: var(--light);
}
h1, h2 {
    font-family: 'Black Ops One', sans-serif !important;
    text-transform: uppercase;
}

.h1,
.h2 {
    font-family: 'Black Ops One', sans-serif !important;
    text-transform: uppercase;
}

p,
div,
a,
input,
body {
    font-family: "Inter", sans-serif !important;
    font-size: 1.2rem;
}

h3,
h4,
h5,
h6, .h3,
.h4,
.h5,
.h6 {
    font-family: "Inter", sans-serif !important;
    text-transform: uppercase;
    font-weight: 700;
}

.display-2, .display-3, .display-4, .display-5 {
    font-weight: 700;
}




html {
  width: 100vw;
  max-width: 100vw;
  max-height: 100vh;
  overflow-x: hidden;
}

#root {
  width: 100vw;
  overflow: hidden;
}

#checkout #card-element input:-internal-autofill-selected {
  color: white !important
}

.bg-image {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.hidden {
  visibility: hidden;
}

.app-link {
  cursor: pointer;
  position: relative;
}

.user-thumbnail {
  width: 40px;
  height: 40px;
  border-radius: 150px;
  object-fit: cover;
}

.home-row {
  height: 50vh;
}

.home-img {
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.login-icon {
  max-width: 70px;
}

.transfer-deposit {
  max-height: 400px;
  object-fit: contain;
  object-position: left;
}

.alert {
  z-index: 1000;
}

.nav-container {
  overflow-y: scroll;
  max-height: 75vh;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.nav-container::-webkit-scrollbar {
  display: none;
}

.product-img {
  height: 500px;
  max-width: 100%;
  width: 100%;
  border-radius: 10px 10px 0px 0px;
  object-fit: cover;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.react-datetime-picker__wrapper {
  border: 0px !important;
}

.panel-mobile-menu {
  display: none;
}

.panel-header-logo {
  max-width: 65px;
}

.form-control {
  color: #fff;
}



.video-form-thumbnail {
  height: 200px;
  object-fit: cover;
}

.capitalize {
  text-transform: capitalize;
}

.card-type {
  max-width: 50px;
}

#fileInput {
  visibility: hidden;
}

.image-input-container {
  background-color: none;
  width: 200px;
  height: 50px;
  border-radius: 10px;
  margin-bottom: 12px;
}

.bmOFCG {
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.hidden {
  display: none;
}

.text-large {
  font-size: 1em;
}

.carousel-inner {
  border-radius: 10px;
}

.carousel-img {
  width: 100%;
  border-radius: 10px;
  max-height: 50vh;
  object-fit: cover;
}

.schedule-class {
  border-radius: 10px;
  overflow: hidden;
}

.class-container {
  height: 200px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.text-auto {
  text-transform: none;
}

.vigencia {
  font-size: 18px;
}

.schedule-class {
  cursor: pointer;
}

.schedule-class:hover {
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.15) !important;
}

.schedule-col {
  width: calc(100% / 7);
}

.schedule-container {
  width: 170%;
}

.br-10 {
  border-radius: 10px;
}

.content {
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}

.video-panel {
  position: relative;
}

.video-panel-title {
  position: absolute;
  bottom: 16px;
  left: 16px;
}

.video-panel-overlay {
  background-color: #000;
  position: absolute;
  height: 50vh;
  opacity: 0.5;
  width: 100%;
  left: 0px;
  top: 0px;
}

.video-panel-img {
  height: 50vh;
  object-fit: cover;
}

.swipe-container {
  max-width: 100%;
  display: flex;
  overflow-x: scroll;
  transition: all 0.5s;
  scroll-behavior: smooth;
  margin-bottom: 16px;
}

.swipe-container::-webkit-scrollbar {
  display: none;
}

.day-card .card {
  min-height: 135px;
}

.card {
  padding: 24px;
  border-radius: 10px;
  transition: all 0.25s;
}

.card:hover {
  cursor: pointer;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.15) !important;
}

.payment-method {
  max-width: 75px;
}

.color-legend {
  height: 25px;
  width: 25px;
  border-radius: 5px;
}

.user-card {
  position: absolute;
  width: 290px;
  right: 12px;
  transition: all 0.2s;
  position: absolute !important;
  cursor: pointer;
  border-radius: 10px;
  z-index: 1;
}

.sale-ribbon {
  position: absolute;
  right: 0;
  top: 20px;
}

.head-coach-ribbon {
  position: absolute;
  font-weight: 800;
  color: #000;
  left: 0px;
  top: 20px;
  width: 30px;
}

.strike-through {
  text-decoration: line-through;
}

.transition {
  transition: all 1s;
}

.video-iframe {
  width: 99%;
  height: 75vh;
}

.slideshow-logo {
  max-width: 450px;
}

.slideshow-img {
  height: 100vh;
  object-fit: cover;
}

.slideshow-text {
  position: absolute;
  top: 25%;
  padding: 16px;
  z-index: 10;
  max-width: 600px;
}

.z-10 * {
  z-index: 10;
}

.hero-content {
  max-width: 600px;
}

.carousel-overlay {
  background: linear-gradient(90deg,
      rgba(0, 0, 0, 1) 75%,
      rgba(255, 255, 255, 0) 150%);
  opacity: 0.5;
  position: absolute;
  display: block;
  height: 80vh;
  width: 100%;
  top: 0;
}

.btn-primary {
  border: 0px;
}

.menu-category {
  font-size: 1.2rem;
  width: 100%;
}


.collapse-nav-item {
  color: #fff;
  display: block;
  width: 100%;
  text-decoration: none !important;
  letter-spacing: 0.5px;
  font-size: 1.2rem;
  padding-top: 12px;
  padding-bottom: 12px;
}


.collapse-nav-item {
  padding-left: 32px;
}

.collapse-nav-item:hover {
  color: #ababab;
}

.collapsible {
  transition: all 0.1s;
  overflow: hidden;
}

.closed {
  height: 0px;
}

body {
  width: 100%;
  max-width: 100%;
  background-color: #000;
}

.navbar {
  padding-top: 0px;
  padding-bottom: 0px;
  z-index: 200 !important;
}

#navbar {
  max-width: 100vw;
  overflow: hidden;
}

.nav-item {
  height: 60px;
  padding-left: 12px;
  padding-right: 12px;
  color: #fff;
  font-weight: 800;
}

.navbar-cta {
  margin-top: 10px;
  transition: all 0.25s;
}

.navbar-cta:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}


.navbar-brand {
  padding-top: 0px;
}

.nav-link {
  text-transform: uppercase;
  display: inline-block;
  color: inherit;
}

.nav-link:hover {
  color: inherit;
}

.navbar {
  position: fixed;
  left: 0;
  width: 100%;
  z-index: 1;
}

.top-banner {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  height: 30px;
  text-align: center;
  font-weight: 800;
  padding: 2px;
  width: 100%;
  overflow: hidden;
  cursor: pointer;
}

.btn:not(.btn-sm) {
  text-transform: uppercase;
  border-radius: 10px;
  padding: 12px 24px;
  font-weight: 800;
}

.logo-landing {
  max-width: 300px;
}

.text-funny {
  font-size: 4rem;
}

.text-left {
  text-align: left !important;
}

.side-menu {
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  position: fixed;
  left: 0;
  width: 160px;
  color: #fff;
  padding-top: 32px;
  padding-bottom: 64px;
}

.main-panel {
  padding-top: 32px;
  min-height: 100vh;
}

.dropdown-item {
  color: #fff;
}


.logo-footer {
  max-width: 250px;
}

.h-80 {
  height: 85vh;
  overflow-y: scroll;
}


.hover-overlay {
  background-color: rgba(0, 0, 0, 0.8);
  height: 100%;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10px;
  cursor: pointer;
  transition: all 1s;
  padding: 16px;
}

.profile-image {
  max-width: 300px;
}

.video-background {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 90vh
}

.thumbnail {
  max-width: 16px;
}

.profile-thumbnail {
  border-radius: 100px;
  max-width: 100%;
}

.video-thumbnail {
  width: 100%;
  height: 100%;
  max-width: 100%;
  object-fit: cover;
  border-radius: 10px 10px 0px 0px;
}

.card-title {
  font-size: 2em;
  font-weight: bold;
}

.card-video-title {
  font-weight: 800;
}

.card-video {
  border-radius: 10px;
  height: 350px;
  margin-bottom: 32px;
  padding-bottom: 16px;
  color: #fff;
}

.card-video:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.card-video p {
  font-size: 14px;
}

.card-video h3 {
  font-size: 16px;
}

.banner-bg {
  padding-top: 150px;
  padding-left: 32px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 500px;
}

.panel-title {
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  margin-bottom: 32px;
}

.filter-card {
  border-radius: 10px;
  padding: 16px;
  margin-bottom: 24px;
}

.bold {
  font-weight: 700;
}

.cursor-pointer {
  cursor: pointer;
}

.text-right {
  text-align: right;
}

.latest-card {
  height: 60vh;
  margin-bottom: 32px;
  cursor: pointer;
  overflow: hidden;
  border-radius: 10px;
}

.latest-card-thumbnail {
  width: 100%;
  max-width: 100%;
  height: 100%;
  object-fit: cover;
}

.latest-card-overlay {
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 1) 70%,
    rgba(255, 255, 255, 0) 100%
  );
  opacity: 0.8;
  height: 100%;
  position: absolute;
  display: block;
  top: 0;
  width: 75%;
}

.latest-card-content {
  position: absolute;
  width: 40%;
  padding: 16px;
  top: 25%;
}
.latest-card-content h2 {
  font-size: 3em;
}

.user-name {
  font-size: 20px;
}

.user {

  border-radius: 200px;
  color: #fff;
  text-align: center;
  align-items: center;
  display: flex;
  width: 50px;
  height: 50px;
  margin-left: auto;
}

.menu-logo {
  max-width: 75px;
}

.side-nav {
  display: block;
  margin-top: 32px;
  list-style-type: none;
  padding-left: 0px;
}

.side-nav li {
  margin-bottom: 16px;
}

.logout-container {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  position: absolute;
  bottom: 16px;
  width: 75%;
}


.home-video {
  height: 90vh;
  object-fit: cover;
}

#video-row {
  margin-top: 60px !important;
}

.logo-navbar {
  max-width: 45px;
}

.trailer {
  max-width: 1100px;
  width: 100%;
}


.mvh-100 {
  min-height: 100vh;
}

.text-banner {
  -webkit-animation: animate 60s linear infinite;
          animation: animate 60s linear infinite;
}

@-webkit-keyframes animate {
  0% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
}

@keyframes animate {
  0% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
}

#main-landing {
  overflow-x: hidden;
  max-width: 100vw;
}

.login-card {
  max-width: 500px !important;
  margin: auto;
  display: block;
}

.login-image {
  object-fit: cover;
  object-position: bottom;
}

.no-scale {
  -webkit-transform: none !important;
          transform: none !important;
}

.no-decoration {
  text-decoration: none !important;
}

.no-decoration:hover {
  text-decoration: none !important;
}

.user-menu {
  margin-left: -22%;
  color: #fff;
}

.class-type {
  height: 70vh;
  border-radius: 10px;
  transition: all 0.5s;
}

.select-image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
  opacity: 0.5;
  border-radius: 10px;
}

.type-disabled {
  opacity: 0.5;
}

.class-card {
  background-color: #000;
  z-index: 1;
  border-radius: 10px;
}

.class-type:hover {
  transition: all 0.25s;
  -webkit-transform: scale(1.01);
          transform: scale(1.01);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.class-card-wrapper:hover {
  -webkit-transform: scale(1.01);
          transform: scale(1.01);
  transition: all 0.5s;
}

.select-title {
  color: #fff;
  z-index: 2;
  text-transform: uppercase;
}

.br-0 {
  border-radius: 0px !important;
}

.bottom-navbar {
  position: fixed;
  z-index: 10;    
  bottom: 0px;
  width: 100%;
}

.bottom-navbar i {
  font-size: 24px;
}

.drawer-menu {
  z-index: 1;
  transition: all 0.25s;
}

.navbar-dark .navbar-nav .nav-link {
  color: #fff;
}

.list-style-none {
  list-style-type: none;
  padding-left: 0px;
}

.mobile {
  border-radius: 10px;
}

.show-tablet {
  display: none;
}

.landing-logo {
  width: 350px;
}

.landing-container {
  z-index: 1;
}

.landing-row {
  height: 500px;
  position: absolute;
  top: 20vh;
  width: 100%;
}

.landing-button {
  transition: all 0.25s;
}

.landing-button:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}


.bold {
  font-weight: 800;
}

.z-index-1 {
  z-index: 1;
}

.mw-500 {
  max-width: 500px;
}

.h-auto {
  height: auto;
}

.background-image {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.banner {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.package-description {
  max-height: 100px;
  overflow: hidden;
}

.package-card {
  height: 350px;
}

.strikethrough {
  text-decoration: line-through;
}

.landing-class-type {
  opacity: 1;
  display: block;
  width: 100%;
  height: 500px;
  max-height: 500px;
  object-fit: cover;
  transition: 0.5s ease;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  z-index: 1;
}

.landing-class-type:after {
  background-color: #000;
  width: 100%;
  height: 100%;
}

.coach-home-img {
  height: 400px;
  object-fit: cover;
}

.black-overlay {
  background-color: #000;
  display: block;
  opacity: 0.4;
  height: 100%;
  width: 100%;
  z-index: 0;
  top: 0;
}

label, span, input, .btn-sm {
  font-size: 16px;
}

.class-type-card {
  height: 200px;
  position: relative;
}

.video-card {
  height: 250px;
  position: relative;
}

.video-card-caption  {
  flex-direction: column;
  position: absolute;
  align-items: end;
  display: flex;
  width: 100%;
  bottom: 0px;
  z-index: 2;
}

.video-card .badge {
  font-size: 12px;
}

.video-card img {
  width: auto;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.video-card p {
  font-size: 14px !important;
}

.video-card-title {
  max-width: 100%;
  height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.video-instructor {
  height: 20px;
  overflow: hidden;
}

.class-type-card img {
  height: 100%;
  width: 100%;
  border-radius: 10px;
  object-fit: cover;
}

.class-type-overlay {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  opacity: 0.25;
  z-index: 1;
  position: absolute;
  background: black;
  border-radius: 10px;
}

.class-type-caption {
  position: absolute; 
  z-index: 2;
  width: 100%;
  bottom: 0px;
  height: 100px;
  display: flex;
  align-items: end;
}

.class-type-caption p {
  font-size: 16px;
}

.class-type-description {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 75%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.landing-class-type-container {
  position: relative;
  overflow: hidden;
}
.landing-class-type-container:hover .landing-class-type {
  -webkit-filter: brightness(20%);
          filter: brightness(20%);
}

.landing-class-type-container:hover .landing-class-type:after {
  opacity: 0.4;
}

.landing-class-type-container:hover .class-type-description {
  opacity: 1;
  color: #fff;
}
.landing-class-type-container:hover .class-type-logo {
  opacity: 0;
}

.class-type-logo {
  position: absolute;
  top: 200px;
  width: 50%;
  height: 50px;
  object-fit: contain;
  left: 25%;
  text-align: center;
}

.show-mobile {
  display: none;
}

.banner-inner {
  max-width: 1200px;
  margin: auto;
  overflow: hidden;
}

.class-package-thumbnail {
  max-width: 400px;
}

.bg-vertical {
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url(/static/media/bg-login.a5f2e263.jpg);
}

div.app-landing {
  width: 100%;
  position: absolute;
  padding-bottom: 4rem !important;
  bottom: 0px;
}

.app-landing .container {
  max-width: 550px;
}

#scroll-text {
  /* animation properties */
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  -webkit-animation: text-scroll 15s linear infinite;
  animation: text-scroll 15s linear infinite;
}

.container.checkout {
  min-height: 82vh;
}

/* for Firefox */

/* for Chrome */
@-webkit-keyframes text-scroll {
  from {
    -webkit-transform: translateX(100%);
  }

  to {
    -webkit-transform: translateX(-100%);
  }
}

@keyframes text-scroll {
  from {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }

  to {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

@media (min-width: 1000px) {
  .hide-desktop {
    display: none;
  }
  .class-type-card {
    height: 300px;
  }
}

@media (max-width: 1000px) {
  .text-left-tablet {
    text-align: left !important;
  }
  .hide-tablet {
    display: none;
  }
  .show-tablet {
    display: inherit;
  }
}

@media (max-width: 767px) {
  body {
    max-height: 100vh;
  }
  .video-iframe {
    height: 35vh;
  }
  .display-1 {
    font-size: 2.8rem !important;
  }
  h2.h3 {
    font-size: 20px !important;
  }
  .bottom-navbar {
    display: block;
  }
  .container-fluid.p-5 {
    padding: 12px !important;
  }
  .app-link-overlay {
    height: 25vh;
  }
  .home-img {
    height: 50%;
  }
  .schedule-mobile-container {
    overflow-x: scroll;
  }
  .schedule-mobile-wrapper {
    width: 350%;
  }
  .show-mobile {
    display: block;
  }
  .schedule-col {
    width: calc(100% / 7);
    margin-top: 32px;
  }
  .panel-mobile-menu {
    display: block;
  }
  .hide-mobile {
    display: none;
  }
  .side-menu {
    display: none;
  }
  .main-panel {
    padding-left: 0px;
  }
  .text-funny {
    font-size: 32px;
  }
  .user-card {
    display: none;
  }
  .nav-item {
    height: auto;
    padding-left: 8px;
    padding-right: 8px;
  }
  .nav-link {
    margin-top: 0px !important;
    text-transform: capitalize !important;
  }
  .mobile {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }
  .schedule-container {
    width: 100%;
  }
  .h-80 {
    height: auto;
  }
  .slideshow-logo {
    max-width: 250px;
  }
  .latest-card-content {
    width: 100%;
  }
  .latest-card-overlay {
     width: 100%; 
  }
  .mobile-center {
    text-align: center;
  }
  .carousel-caption {
    bottom: 20% !important;
  }
  .carousel-caption .display-2 {
    font-size: 2rem;
  }
  #hero h1 {
    font-size: 5em;
  }
}

